//
// Col form label
//

.dark-mode {
  background-color: #12121B;
  --wl-btn-background-primary-collor: #007ae5;
}

.dark-mode .form-section {
  background-color: #121212;
}

.dark-mode .fieldset-group {
  border: 1px solid #636B7B !important;
}

.dark-mode .fieldset-legend {
  color: #E9EAED;
}

.dark-mode .form-control {
  background-color: transparent!important;
  border-color: transparent!important;
  color: #E9EAED!important;
}

.dark-mode .select2-selection__rendered {
  color: #E9EAED;
}

.dark-mode .nav-line-tabs .nav-item .nav-link {
  color: #9696F1;
}

.dark-mode .nav-line-tabs .nav-item .nav-link.active,
.dark-mode .nav-line-tabs .nav-item.show .nav-link,
.dark-mode .nav-line-tabs .nav-item .nav-link:hover:not(.disabled) {
  border-bottom-color: #9696F1;
}

.dark-mode .nav-line-tabs {
  border-bottom-color: #232C3D;
}

.dark-mode .fieldset-input {
  [class$="singleValue"] {
    color: #E9EAED;
  }
}

.dark-mode .btn.btn-wl-custom-primary-collor {
  background-color: #6666DE;
}

.dark-mode .btn.btn-wl-custom-primary-collor i[class^=fa-],
.dark-mode .btn.btn-wl-custom-primary-collor i[class*=" fa-"] {
  background-color: #6666DE;
}


.dark-mode .MuiFormLabel-root,
.dark-mode .MuiInputLabel-root,
.dark-mode .MuiInputBase-input {
  color: #E9EAED;
}

.dark-mode .MuiInputBase-root fieldset {
  border-color: #636B7B;
}

.dark-mode .custom-checkbox-input,
.dark-mode .form-check-label,
.dark-mode .custom-radio-input {
  color: #E9EAED;
}

.dark-mode .form-switch .form-check-input {
  background-color: #FFF;
}

.dark-mode .form-switch .form-check-input:checked {
  background-color: var(--wl-btn-background-primary-collor);
}

.dark-mode .label-card,
.dark-mode .label-detail-card,
.dark-mode .tab-content,
.dark-mode .modal-header,
.dark-mode .fw-bolder {
  color: #E9EAED;
}

.dark-mode .card{
  background-color: rgb(35, 38, 44);
}

.dark-mode .dropdown-card {
  background-color: #121212;
}


.dark-mode .modal-entity-detail,
.dark-mode .modal-content {
  background-color: rgb(35, 38, 44);
  border: '1px solid #ddd';
}

.dark-mode .modal-header .btn-close {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23FFFFFF'><path d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/></svg>");
}

.dark-mode .modal-header {
  border-color: rgb(66, 58, 58) !important;
}


.dark-mode .badge-atv {
  background-color: #3f4339 !important
}

.dark-mode .cross{
  color: #E9EAED !important;
}

.dark-mode .MuiSvgIcon-root path {
  fill: #fff !important;
}

.dark-mode .btn.btn-sm.btn-wl-custom-link.btn-wl-custom-hover-danger:not(:hover) {
  color: #fff !important;
}

.dark-mode .menu.menu-sub.menu-sub-dropdown.funnel-stage-card-options.dropdown-card.show a,
.dark-mode .menu.menu-sub.menu-sub-dropdown.funnel-stage-card-options.dropdown-card.show i {
  color: #b5b5c3 !important;
}

.dark-mode .table-tr{
  color: #E9EAED !important;
}
.dark-mode .css-1dimb5e-singleValue, .dark-mode .css-kyib88-Input{
  color: #E9EAED !important;
}
