.modal-form-manage {
    height: 100vh;
}

.modal-form-edit-field {
    max-width: 450px;
}

.form-mount-field {
    display: flex;
    flex-direction: column;
    border: 1px dashed transparent;
    cursor: move;
    border-radius: 8px;
    padding: 15px;
}

.form-mount-field:hover {
    border: 1px dashed var(--bs-primary);
}

.form-mount-input-field {
    cursor: move;
}

.form-mount-field-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.form-mount-field-btn-edit {
    padding: 5px 12px 5px 12px !important;
    border-radius: 2.5em;
    background-color: transparent !important;
}

.form-mount-field-btn-edit:hover {
    background-color: #dfe5ff !important;
}

.form-mount-field-btn-edit-icon {
    padding: 0px !important;
    color: #606161 !important;
}

.btn-floating-save {
	position:absolute;
	width:60px;
	height:60px;
	bottom:40px;
	right:40px;
	background-color:#FFF;
	color:#000;
	border-radius:50px;
	text-align:center;
	box-shadow: 2px 2px 3px #999;
    cursor: pointer;
}

.btn-floating-save-icon {
    font-size: 2.5rem !important;
	margin-top: 15px;
}