//
// Col form label
//

.funnel {
  background-color: white;
  flex-grow: 1;
  overflow-x: auto;
  overflow-y: hidden;
  height: calc(100vh - 77px);
  overflow-x: visible;
}

.funnel-stage {
  width: 325px;
  min-width: 325px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 0.425rem;
  margin-right: 1rem;
}

.funnel-stage .card-body {
  padding: 5px 1.25rem 15px 1.25rem;
}

.funnel-stage .card-body-column {
  padding: 1.25rem 0rem 1.25rem 0rem;
}

.stage-add {
  // background-color: #00000014;
  height: fit-content;
  cursor: pointer;
  width: 300px;
  min-width: 300px;
  margin-right: 1rem;
}

.stage-header {
  margin-top: 1rem;
  // margin-bottom: 1rem;
  margin-left: 1rem;
  margin-right: 1rem;
}

.stage-title {
  color: #606161;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
}

.funnel-stage-card {
  width: 100%;
  min-width: 40px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 0.425rem;
  margin-right: 1rem;
  margin-bottom: 1rem;
  background-color: #ffffff;
}

.funnel-stage-card-header {
  margin-top: 1rem;
  margin-left: 1rem;
  margin-right: 1rem;
}

.funnel-stage-card-title {
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
}

.btn-open-op-card {
  font-size: 1rem;
}

.funnel-stage-card-options ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  background-color: transparent;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
}

.funnel-stage-card-options li a {
  display: block;
  color: #606161;
  padding: 8px 16px;
  text-decoration: none;
}

/* Change the link color on hover */
.funnel-stage-card-options li a:hover {
  background-color: #f5f6f8;
}

.text-area-note {
  height: 1em;
  max-height: 20em;
  transition: all 0.5s ease;
}

.text-area-note:focus {
  min-height: 10em;
}

.note-card {
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;
  margin-top: 10px;
  padding: 5px;
}
