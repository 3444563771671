//
// Col form label
//

.fieldset-group {
  border-radius: 4px;
  padding-left: 0.5rem !important;
  border: 1px solid rgba(0, 0, 0, 0.23) !important;
}

.fieldset-legend {
  color: #909191;
  float: none !important;
  width: auto !important;
  font-size: 0.8rem;
  padding-left: 0.3rem;
  padding-right: 0.3rem;
  margin-bottom: 0.1rem;
  
}

.fieldset-group .fieldset-input {
  border: none !important;
  padding-top: 0rem;
  padding-bottom: 0.4rem;
  padding-left: 0.2rem;
  background-color: transparent;
}

.form-check-label {
  color: black;
}

.MuiDateCalendar-root {
  width: 250px !important;
}

.MuiDateCalendar-root, .MuiTimeClock-root {
  width: 250px !important;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24 {
  min-width: 250px !important;
}

.label-card {
  color: black;
}

.card {
  background-color: '#FFFF'
}

.label-detail-card {
  color: #555;
}

.no-scroll {
  scrollbar-color: auto !important;
  overflow-x: hidden;
}

.cross{
  color: #000!important;
}

.integration-cards-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr); 
  gap: 16px; 
  padding: 16px;
  height: calc(100% - 77px);
  justify-content: flex-start;
  background-color: #fff; 
  border-radius: 0.625rem;
}

.integration-card {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px; 
  padding: 12px;
  max-height: 80px;    
  text-align: center;
  cursor: pointer;
  transition: transform 0.3s ease;
  display: flex;          
  align-items: center;    
  justify-content: center;
}

.integration-card:hover {
  transform: translateY(-5px); 
}

.custom-quill .ql-container {
  min-height: 200px;
}

.custom-input-email {
  height: 25px !important;
  min-height: 25px !important;
  border-style: none !important;
  background-color: transparent !important;
  box-shadow: none !important;
}
