.modal-entity-detail {
    height: 100vh;
}

.modal-entity-detail .modal-header {
    border-bottom: 0px;
    padding: 1rem;
}

.modal-entity-detail .modal-body {
    padding-top: 1px
}

.modal-entity-detail .nav-link {
    font-size: 12px;
    font-weight: 700;
    margin-bottom: 3px;
}

.modal-entity-detail .nav-link.active {
    color: #0000C8;
}

.entity-relation-card {
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-radius: 4px;
    margin-top: 10px;
    padding: 5px;
  }