:root {
  --wl-btn-background-primary-collor: var(--wl-first);
  --wl-btn-text-primary-collor: #ffffff;
  --wl-btn-hover-text-primary-collor: #ffffff;

  --wl-btn-background-secondary-collor: #f9f9f9;
  --wl-btn-hover-background-secondary-collor: var(--wl-second);
  --wl-btn-text-secondary-collor: #7e8299;
  --wl-btn-hover-text-secondary-collor: #ffffff;

  --wl-text-title-primary-collor: var(--wl-first);

  --wl-link-collor: var(--wl-first);
  --wl-link-hover-collor: #181c32;

  --wl-menu-collor: #5e6278;
  --wl-menu-hover-collor: #3f4254;
  --wl-menu-active-collor: var(--wl-first);

  --wl-success-background-collor: rgba(96, 255, 95, 0.08);
  --wl-success-text-collor: #606161;
  --wl-success-border-collor: rgb(26, 155, 24);
  --wl-success-hover-background-collor: #f1efef;
  --wl-success-text-hover-collor: #606161;

  --wl-danger-background-collor: rgba(255, 103, 95, 0.08);
  --wl-danger-text-collor: #606161;
  --wl-danger-border-collor: #ff675f;
  --wl-danger-hover-background-collor: #f1efef;
  --wl-danger-text-hover-collor: #606161;

  --wl-late-background-collor: #f7dbba;
  --wl-late-text-collor: #ff8c00;
  --wl-late-border-collor: #f7dbba;
  --wl-late-hover-background-collor: rgba(255, 140, 0, 0.363);
  --wl-late-text-hover-collor: #ff8c00;

  --wl-warning-background-collor: transparent;
  --wl-warning-text-collor: #606161;
  --wl-warning-border-collor: rgba(0, 0, 0, 0.23);
  --wl-warning-hover-background-collor: #f1efef;
  --wl-warning-text-hover-collor: #606161;

  --wl-info-background-collor: #cee7f7;
  --wl-info-text-collor: #606161;
  --wl-info-border-collor: rgba(0, 0, 0, 0.23);
  --wl-info-hover-background-collor: #f1efef;
  --wl-info-text-hover-collor: #606161;
}

.btn.btn-wl-custom-primary-collor {
  background-color: var(--wl-btn-background-primary-collor);
  color: var(--wl-btn-text-primary-collor);
}

.btn.btn-wl-custom-primary-collor i[class^='fa-'],
.btn.btn-wl-custom-primary-collor i[class*=' fa-'] {
  background-color: var(--wl-btn-background-primary-collor);
  color: var(--wl-btn-text-primary-collor);
}

.btn.btn-wl-custom-primary-collor-inverse {
  background-color: rgb(245, 246, 247);
  color: var(--wl-btn-background-primary-collor);
}

.btn.btn-wl-custom-primary-collor-inverse i {
  color: var(--wl-btn-background-primary-collor);
}

.btn.btn-wl-custom-primary-collor-inverse:hover {
  background-color: var(--wl-btn-background-primary-collor);
  filter: brightness(120%);
  color: var(--wl-btn-text-primary-collor);
}

.btn.btn-wl-custom-primary-collor-inverse:hover i {
  color: var(--wl-btn-hover-text-primary-collor);
}

.btn.btn-wl-custom-tertiary-collor {
  background-color: var(--wl-btn-background-primary-collor);
  color: var(--wl-btn-text-primary-collor);
}

.btn.btn-wl-custom-tertiary-collor:hover {
  background-color: var(--wl-btn-background-primary-collor);
  color: var(--wl-btn-text-primary-collor);
}

.btn-wl-custom-primary-collor-inverse-border {
  display: inline-block;
  padding: 4px 25px 4px 25px;
  background-color: white;
  border: 1px solid var(--wl-btn-background-primary-collor);
  color: var(--wl-btn-background-primary-collor);
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s, color 0.3s;
  border-radius: 5px;
  font-weight: bold;
}

.btn-wl-custom-primary-collor-inverse-border:hover {
  color: white;
  background-color: var(--wl-btn-background-primary-collor);
}

.btn.btn-wl-custom-link {
  background-color: transparent;
  color: var(--wl-btn-background-primary-collor);
}

.btn.btn-wl-custom-hover-danger:hover {
  color: red;
}

.detail-menu-item-badge {
  font-size: 12px;
  box-shadow: 0 0 0 1px var(--wl-btn-background-primary-collor);
  color: var(--wl-btn-background-primary-collor);
  margin-right: 8px;
  padding: 5px 9px;
  cursor: pointer;
  border-radius: 4px;
}

.detail-menu-item-badge.active {
  background-color: var(--wl-third);
}

.btn.btn-wl-custom-primary-collor:hover {
  filter: brightness(120%);
  color: var(--wl-btn-hover-text-primary-collor);
}

.btn.btn-wl-custom-secondary-collor {
  background-color: var(--wl-btn-background-secondary-collor);
  color: var(--wl-btn-text-secondary-collor);
}

.btn.btn-wl-custom-secondary-collor:hover {
  background-color: var(--wl-btn-hover-background-secondary-collor);
  color: var(--wl-btn-hover-text-secondary-collor);
}

.btn.btn-wl-custom-secondary-collor i {
  color: var(--wl-btn-text-secondary-collor);
}

.btn.btn-wl-custom-secondary-collor:hover i {
  color: var(--wl-btn-hover-text-secondary-collor);
}

.text-wl-custom-title-collor {
  color: var(--wl-text-title-primary-collor);
}

a {
  color: var(--wl-link-collor);
}

a:hover {
  color: var(--wl-link-hover-collor);
}

[data-kt-app-layout='light-sidebar'] .app-sidebar .menu .menu-item .menu-link,
[data-kt-app-layout='light-sidebar'] .app-sidebar .menu .menu-item.here > .menu-link .menu-title,
[data-kt-app-layout='light-sidebar'] .app-sidebar .menu .menu-item.here > .menu-link .menu-icon i,
[data-kt-app-layout='light-sidebar']
  .app-sidebar
  .menu
  .menu-item.hover:not(.here)
  > .menu-link:not(.disabled):not(.active):not(.here)
  .menu-icon
  i,
[data-kt-app-layout='light-sidebar']
  .app-sidebar
  .menu
  .menu-item.hover:not(.here)
  > .menu-link:not(.disabled):not(.active):not(.here)
  .menu-title,
[data-kt-app-layout='light-sidebar'] .app-sidebar .menu .menu-item .menu-link .menu-title,
[data-kt-app-layout='light-sidebar'] .app-sidebar .menu .menu-item .menu-link .menu-icon i {
  color: var(--wl-menu-collor);
}

[data-kt-app-layout='light-sidebar'] .app-sidebar .menu .menu-item .menu-link .menu-bullet .bullet {
  background-color: var(--wl-menu-collor);
}

[data-kt-app-layout='light-sidebar']
  .app-sidebar
  .menu
  .menu-item:not(.here)
  .menu-link:hover:not(.disabled):not(.active):not(.here),
[data-kt-app-layout='light-sidebar']
  .app-sidebar
  .menu
  .menu-item:not(.here)
  .menu-link:hover:not(.disabled):not(.active):not(.here)
  .menu-icon
  i,
[data-kt-app-layout='light-sidebar']
  .app-sidebar
  .menu
  .menu-item:not(.here)
  .menu-link:hover:not(.disabled):not(.active):not(.here)
  .menu-title {
  color: var(--wl-menu-hover-collor);
}

[data-kt-app-layout='light-sidebar']
  .app-sidebar
  .menu
  .menu-item:not(.here)
  .menu-link:hover:not(.disabled):not(.active):not(.here)
  .menu-bullet
  .bullet {
  background-color: var(--wl-menu-hover-collor);
}

[data-kt-app-layout='light-sidebar'] .app-sidebar .menu .menu-item .menu-link.active .menu-title,
[data-kt-app-layout='light-sidebar'] .app-sidebar .menu .menu-item .menu-link.active .menu-icon i {
  color: var(--wl-menu-active-collor);
}

[data-kt-app-layout='light-sidebar']
  .app-sidebar
  .menu
  .menu-item
  .menu-link.active
  .menu-bullet
  .bullet {
  background-color: var(--wl-menu-active-collor);
}

.badge-success-wl-custom-collor {
  background-color: #e8fff3;
  color: #50cd89;
}

.badge-danger-wl-custom-collor {
  background-color: #fff5f8;
  color: #f1416c;
}

.success-wl-custom-collor {
  background-color: var(--wl-success-background-collor);
  color: var(--wl-success-text-collor);
  border-color: var(--wl-success-border-collor);
}

.success-wl-custom-collor:hover {
  background-color: var(--wl-success-hover-background-collor);
  color: var(--wl-success-text-hover-collor);
}

.danger-wl-custom-collor {
  background-color: var(--wl-danger-background-collor);
  color: var(--wl-danger-text-collor);
  border-color: var(--wl-danger-border-collor);
}

.danger-wl-custom-collor:hover {
  background-color: var(--wl-danger-hover-background-collor);
  color: var(--wl-danger-text-hover-collor);
}

.info-wl-custom-collor {
  background-color: var(--wl-info-background-collor);
  color: var(--wl-info-text-collor);
  border-color: var(--wl-info-border-collor);
}

.info-wl-custom-collor:hover {
  background-color: var(--wl-info-hover-background-collor);
  color: var(--wl-info-text-hover-collor);
}

.late-wl-custom-collor {
  background-color: var(--wl-late-background-collor);
  color: var(--wl-info-text-hover-collor);
  border-color: var(--wl-late-border-collor);
}

.late-wl-custom-collor:hover {
  background-color: var(--wl-late-hover-background-collor);
  color: var(--wl-info-text-hover-collor);
}

.warning-wl-custom-collor {
  background-color: var(--wl-warning-background-collor);
  color: var(--wl-warning-text-collor);
  border-color: var(--wl-warning-border-collor);
}

.warning-wl-custom-collor:hover {
  background-color: var(--wl-warning-hover-background-collor);
  color: var(--wl-warning-text-hover-collor);
}

#kt_table_users tbody tr:hover {
  background-color: var(--wl-third);

  color: #ffffff;
}

#kt_table_users tbody tr:hover svg {
  color: #ffffff;
}

.list-custom-wl {
  padding: 10px;
  padding-bottom: 0px;
  border-radius: 5px;
  cursor: pointer;
}

.list-custom-wl:hover {
  background-color: var(--wl-second);
  color: #ffffff;
}

.list-custom-wl:hover i {
  color: #ffffff;
}

.page-item.active .page-link {
  color: var(--wl-btn-text-primary-collor);
  background-color: var(--wl-btn-background-primary-collor);
}

.page-item:hover:not(.active):not(.offset):not(.disabled) .page-link,
.page-item:hover:not(.active):not(.offset):not(.disabled) .page-link i {
  color: var(--wl-btn-background-primary-collor);
}

.form-check-input:checked {
  background-color: var(--wl-btn-background-primary-collor);
  border-color: var(--wl-btn-background-primary-collor);
}

.custom-form-wl-custom-collor {
  background-color: var(--wl-third);
}

.modal-entity-detail .nav-link.active {
  color: var(--wl-btn-background-primary-collor);
}
.nav-line-tabs .nav-item .nav-link.active,
.nav-line-tabs .nav-item.show .nav-link,
.nav-line-tabs .nav-item .nav-link:hover:not(.disabled) {
  border-bottom-color: var(--wl-btn-background-primary-collor);
}

.link-wl-custom-primary-collor:hover {
  filter: brightness(120%);
  color: var(--wl-first);
}

.link-wl-custom-primary-collor {
  color: var(--wl-first);
}

.link-wl-custom-primary-collor i[class^='fa-'],
.link-wl-custom-primary-collor i[class*=' fa-'] {
  color: var(--wl-first);
}
